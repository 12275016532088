<template>
  <div style="position: relative; height: 100%">
    <div class="contact-background"></div>
    <div class="contact-page">
      <div class="display-2 about-title font-weight-thin">Howdy! Let's Chat!</div>
      <div class="statement font-weight-light">Reach me by e-mail or LinkedIn and I'll respond ASAP. </div>

      <div class="link-section" v-for="site in info.contacts"><a :href="site.link">{{site.title}}</a></div>
    </div>
  </div>
</template>

<script>
import HomeInfo from '../store/home_info.js';

export default {
  name: 'about',
  data() {
    return {
      info: HomeInfo,
      email: HomeInfo.contacts[0].link,
      linkedin: HomeInfo.contacts[1],
    }
  }
};
</script>

<style scoped>
.contact-page {
  position: relative;
  text-align: center;
  max-width: 730px;
  margin: 0 auto 25px auto;
  padding: 0 15px 0 15px;
  color: var(--v-bg_text-base);
}

.statement {
  margin-bottom: 10px;
  text-align: center;
}

.about-section-2 {
  margin-top: 25px;
  margin-bottom: 15px;
  text-align: center;
}

.link-section {
  text-align: center;
}

.link-section a {
  color:var(--v-link_color-base);
}

.about-title {
  text-align: center;
  margin-bottom: 25px;
}

.contact-background {
  position: absolute;
  bottom: 0px;
  width: 100%;
  min-height: 300px;

  background-color: var(--v-bg_accent_03-base);

  mask-image: url('~@/assets/bg_01.png');
  mask-repeat: repeat-x;
  mask-position: center top;
  mask-size: 1022px;

  background-repeat: repeat-x;
  background-position: center top;
  background-size: 1022px;
}


</style>
